.form {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
}

input[type=text], input[type=number], input[type=password], input[type=email], .md-select [type=button] {
  width: 100%;
  height: 46px;
  border-radius: 0.5rem;
  border: 1px solid fade_out($body-color,0.5);
  @extend %default-font;
  @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 1.6/1.2, 1.6);
  line-height: 46px;
  font-weight: 600;
  color: $body-color;
  padding: 0.625rem 1rem 0.625rem 1rem;
  outline: none;
  transition: 0.3s;

  &:focus {
    border-color: $secondary;
    border-width: 2px;
  }
}

.floating-label {
  position: relative;
  input[type=text], input[type=number], input[type=password], input[type=email], textarea {
    + label {
      background-color: #fff;
      transition: .35s cubic-bezier(.49,1,.22,1) 0ms;
      position: absolute;
      top: 2px;
      left: 1.1rem;
      height: 42px;
      @extend %default-font;
     // @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 2.65/1.2, 2.65);
      line-height: 42px;
      font-weight: 600;
      color: fade_out($body-color, 0.5);
      padding: 0 0.25rem;
      cursor: text;
    }
  }

  textarea {
    + label {
      top: 2px;
    }
  }

  &.float {
    input[type=text], input[type=number], input[type=password], input[type=email], textarea {
      + label {
        height: 16px;
        top: -5px;
        @include fluid-type($min_width, $max_width, (15px/1.2), 15px, 1/1.2, 1);
        font-weight: 400;
        color: $secondary;
      }
    }
  }
}

.input-wrapper {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;

  .btn {
    margin-bottom: 0;
    align-self: flex-start;
  }

  &:not(.floating-label) {

    input[type=text], input[type=number], input[type=password], input[type=email], textarea, .md-select {
      order: 2;
    }

    label {
      margin-bottom: 0.5rem;
      order: 1;
    }

    .formError, .invalid-text {
      order: 3;
    }
  }
}

textarea {
  width: 100%;
  line-height: 1.5;
  border-radius: 0.5rem;
  border: 1px solid fade_out($body-color,0.5);
  @extend %default-font;
  @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 1.6/1.2, 1.6);
  font-weight: 600;
  color: $body-color;
  padding: 0.625rem 1rem 0.625rem 1rem;
  outline: none;
  overflow: hidden;
  resize: none;
  transition: 0.3s;

  &:focus {
    border-color: $secondary;
    -webkit-box-shadow:inset 0 0 0 1px $secondary;
    -moz-box-shadow:inset 0 0 0 1px $secondary;
    box-shadow:inset 0 0 0 1px $secondary;
  }
}

input[type=submit], button[type=submit] {
  border: none;
  cursor: pointer;
}

::-webkit-input-placeholder { /* Edge */
  color: fade_out($body-color,0.5);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: fade_out($body-color,0.5)!important;
}

::placeholder {
  color: fade_out($body-color,0.5);
}

//// checkbox styling
.checkbox-wrapper {

}
// checkbox label
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
}

// checkbox
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid fade_out($primary, 0.65);
  border-radius: 5px;
  transition: 0.3s;
}

// checkbox checked
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 16px;
  transition: .3s;
  -webkit-transform: rotateZ(90deg) scale(0);
  transform: rotateZ(90deg) scale(0);
  border-bottom: 4px solid $primary;
  border-right: 4px solid $primary;
  top: -2px;
  left: 2px;
}

[type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: rotateZ(45deg) scale(1);
  transform: rotateZ(45deg) scale(1);
  top: 0px;
  left: 7px;

}
// checkbox disabled
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  background-color: #f9f9f9;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}

// checkbox focus
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border-color: rgba($primary,0.5);
}

// checkbox hover
[type="checkbox"]:checked:hover + label,
[type="checkbox"]:not(:checked):hover + label {
  color: $secondary;
  border-radius: 3px;
}

//// radio button styling
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid $grey;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: $primary;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

////custom file upload input
.custom-upload-input {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;

  button {
    background-color: $secondary;
    padding: 10px 20px;
    border: none;
    border-radius: 17.5px;
    @extend %default-font;
    @include fluid-type($min_width, $max_width, (17.5px/1.6), 17.5px, 0.9, 0.9);
    font-weight: 300;
    text-transform: uppercase;
    cursor: pointer!important;
  }

  input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    font-size: 0;
  }
}

.custom-upload-filename {
  display: inline-block;
  @include fluid-type($min_width, $max_width, (16px/1.2), 16px, (1.4/1.2), 1.4);
}

//new validation
.is-invalid {
  border-color: fade_out($red, 0.5)!important;
  ~.invalid-text {
    display: block;
  }
}

.invalid-text {
  display: none;
  margin-top: 0.25rem;
  @include fluid-type($min_width, $max_width, (17px/1.2), 17px, (1.4/1.2), 1.4);
  color: $red;
}

//old validation
.formError {
  position: relative!important;
  left: 0!important;
  top: 0!important;
  margin-top: 0!important;
  opacity: 1!important;
  order: 2;

  .formErrorContent {
    background: transparent!important;
    border-radius: 0!important;
    color: $red!important;
    font-size: 15px!important;
    font-weight: 500!important;
    padding: 4px 0 4px 0!important;
    box-shadow: none!important;
    -moz-box-shadow: none!important;
    -webkit-box-shadow: none!important;
    -o-box-shadow: none!important;
    border: none!important;
  }
  .formErrorArrow {
    * {
      display: none!important;
    }
  }
}

.md-select {
  *, :after, :before {
    box-sizing: border-box;
  }
  position: relative;
  display: inline-block;
  margin: 0;
  width: 100%;

  label {
    width: 100%;
  }

  [type=button] {
    background: transparent;
    cursor: default;

    @extend %default-font;
    @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 0.6/1.2, 0.6);
    font-weight: 400;

    z-index: 1;
    outline: none;
    overflow: hidden;
    text-align: left;

    &:focus, &:hover {
      cursor: pointer;
    }
    &:after {
      content: '\25be';
      display: inline-block;
      vertical-align: middle;
      float: right;
      width: 50px;
      font-size: 50px;
      line-height: 10px;
      text-align: center;
      transform: scaleY(0.7);
      transition: 0.3s;
    }
  }
  ul[role=listbox] {
    background-color: white;
    cursor: default;
    list-style: none!important;
    @extend %default-font;
    @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 1.6/1.2, 1.6);
    font-weight: 400;
    overflow: hidden;
    margin: 0!important;
    max-height: 0;
    position: absolute;
    padding: 0!important;
    transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.2) !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    li {
      height: 48px;
      margin: 0;
      padding: 10px 16px;
      outline: none;
      overflow: hidden;

      cursor: pointer;
      &:before {
        display: none;
      }

      .custom-checkbox .btn {
        font-size: inherit;
      }
    }
  }

  &.active {
    [type=button] {
      border-color: #454545;

      &:after {
        transform: scaleY(0.7) rotate(180deg);
      }
    }
  }

  &.active ul {
    max-height: 300px;
    overflow: auto;
    padding: 8px 0 16px 0px;
    z-index: 99;
    transition: all .2s ease
  }

  + select.custom-select {
    display: none;
  }
}


@include media-breakpoint-up(md) {
  .form {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

@include media-breakpoint-up(lg) {
  .form {
    margin-left: 0;
    margin-right: 0;
    max-width: 80%;
  }
}