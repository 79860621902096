$bg-color-menu: $primary;
$bg-color-parent: $white;
$bg-color-children: $white;
$text-color: $white;

body.fixed {
  position: fixed;
}

.navbar-toggler {
  display: block;
  background-color: $primary;
  padding-left: 8px;
  height: 56px;
  width: 56px;
  border: 0;
  cursor: pointer;
  .navbar-toggler-icon {
    display: inline-block;
    background-color: $white;
    height: 2px;
    width: 20px;
    border-radius: 1.5px;
    vertical-align: middle;
    &:after, &:before {
      content: "";
      background-color: $white;
      height: 2px;
      width: 20px;
      display: block;
      border-radius: 1.5px;
    }
    &:after {
      margin-top: 11px;
    }
    &:before {
      margin-top: -7px;
    }
  }
}
.sidenav {
  height: 100%;
  width: 90vw;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: -100vw;
  background-color: $bg-color-parent;
  overflow-x: hidden;
  transition: 0.5s;

  .sidenav-header {
    background-color: $primary;
    height: 62px;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    color: $text-color;
    font-size: 18px;
    z-index: 9;
    position: relative;

    .sidenav-control {
      a {
        color: #fff;
        font-size: 40px;
        text-decoration: none;
      }

      i {
        padding: 0 0.8rem;
        vertical-align: middle;
      }

      .closebtn {
        i {
          padding-right: 0;
          font-size: 16px;
        }
      }
    }
  }

  .sidenav-panels {
    background-color: $bg-color-children;
    position: relative;
    height: calc(100% - 62px);
  }
  .sidenav-panel {
    background-color: $bg-color-children;
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    transition: 0.5s;

    &_open {
      left: 0;
      &#panel-menu {
        left: -100%;
      }
    }

    .backbtn {
      background-color: darken($bg-color-menu,5%);
      color: $white!important;
      .custom-arrow {
        min-width: 20px;
        align-items: center;
        justify-content: start;
        &:before {
          border-left-color: $white;
          transform: rotate(-45deg);
        }
      }
    }

    a:not(.btn) {
      padding: 14px 24px 14px 24px;
      display: flex;
      color: $body-color;
      text-decoration: none;
    }

    .btn {
      display: block!important;
      line-height: 35px;
      margin-top: 1rem;
      margin-left: 1.5rem;
      width: calc(100% - 3rem);
      text-align: center;
    }

    i {
      padding: 0 0.5rem 0 0;
      font-size: 20px;
      color: $blue;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      &:first-child {
        background-color: $bg-color-parent;
      }

      li.has-subitems {
        position: relative;
        display: flex;

        a {
          flex: auto;
          pointer-events: none;
        }

        ul {
          display: none;
        }

        i {
          padding: 0 1rem;
          height: 51px;
          font-size: 18px;
          line-height: 51px;
        }

        .custom-arrow {
          min-width: 60px;
          &:before {
            transform: rotate(135deg);
          }
        }
      }

      li.highlight {
        > a {
          font-weight: bold;
          > i {
            display: inline-block;
            position: relative;
            margin-left: 6px;
            transform: rotate(-90deg);
            transition: 0.3s;
            font-size: 8px;
            line-height: 24px;
            vertical-align: middle;
            float: right;
          }
        }
      }

      li.label {
        padding: 8px 24px 8px 24px;

        + li {
          border-top: none;
        }
      }

      li {
        border-top: 1px solid #e7e7e7;
        &.label {
          padding-top: 24px;
          border-bottom: none;
        }
        a:hover {
          background-color: rgba(#ffffff,0.2);
        }
        ul {
          li {
            border-top: none;
            a {
              padding: 0 0;
            }
          }
        }
      }

      > a {
        display: none;
      }
      .submenu-intro {
        display: none;
      }
    }
    &-button-wrapper {
      margin-bottom: 1rem;
      border-top: 1px solid rgba($bg-color-menu,0.25);
    }
  }
  #panel-menu {
    background-color: $bg-color-parent;
    left: 0;

    li {
      border-top: 1px solid rgba($bg-color-menu,0.25);
    }
    a:not(.btn) {
      padding: 14px 24px 14px 24px;
      text-decoration: none;
    }
  }
}

.mobile-menu-active {
  &:before {
    content: "";
    background-color: rgba($black, .5);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    transition: 0.3s;
  }
  .sidenav {
    right: 0;
  }
}

#page-wrapper.mobile-menu-active {
  &:before {
    opacity: 1;
    visibility: visible;
  }
  .sidenav {
    right: 0;
  }
}

.sidenav a {
  transition: 0.3s;
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(lg) {
  .navbar-toggler {
    height: 60px;
    width: 60px;
  }

  .sidenav {
    width: 40vw;
  }
}

@include media-breakpoint-up(xl) {
  .navbar-toggler {
    display: none;
  }
}