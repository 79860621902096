.calendar {
    padding-top: 12px;
    column-count: 1;
    grid-column-gap: 1.875rem;
    //transform: translateZ(0);
    overflow: hidden;

    .text-link:before {
        display: none;
    }

    .month {
        position: relative;
        margin-bottom: 1.875rem;
        //break-inside: avoid;
        //transform: translateZ(0);

        &__label {
            margin-top: -1.5rem;
            display: block;
            margin-bottom: 1rem;

            text-align: center;
            //transform: translate(-50%, -12px);
            //backface-visibility: hidden;
            z-index: 1;
            &__month{
                display: inline-block;
                background-color: $pink;
                color: $white;
                padding: 0.125rem 1rem;
                @include fluid-type($min_width, $max_width, (15px/1.2), 15px, 1.4, 1.4);
                text-transform: uppercase;
            }
        }
    }
    ul {
        background-color: $white;
        list-style: none;
        padding: 0;
        margin: 0;
        //break-inside: avoid-column;

        li {
            position: relative;
            break-inside: avoid;
            padding: 1.5rem 1.875rem;
            color: $body-color;
            text-decoration: none;
            @include transition(0.3s);
            z-index: 0;

            &:first-child:before {
                content: "";
                background: #f0f1f2;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 12px;
                z-index: -1;
            }

            span {
                display: block;
                @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1.4, 1.4);
                font-weight: 700;
            }
        }
    }

    &--home {
        .month {
            //break-inside: auto;
            //transform: none;
        }
        ul {


            li {
                //break-inside: avoid;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .calendar {
        column-count: 2;
    }
}

@include media-breakpoint-up(md) {
    .calendar {

        &[data-columns="3"], &[data-columns="4"]{
            column-count: 3;
        }
    }
}

@include media-breakpoint-up(xl) {
    .calendar {

        &[data-columns="3"]{
            column-count: 3;
        }

        &[data-columns="4"]{
            column-count: 4;
        }
    }
}

.equal-column-reference, .equal-column {
    padding-top: 12px;
}