.image-header {
  min-height: 300px;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__content {
    display:grid;
    grid-template-columns: 1fr 12fr 1fr;

    display: -ms-grid;
    -ms-grid-columns: 1fr 12fr 1fr;

    padding-bottom: 5vw;

    > * {
      grid-column: 2;
      -ms-grid-column: 2;
    }
  }

  &__cta {
    z-index: 9;
  }

  &__background {
    &:after {
      content: "";
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+20,000000+100&0.2+40,0+100 */
      background: -moz-linear-gradient(left, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .bg-progressive {
      z-index: -1;
    }
  }

  &__text {
    margin-bottom: 80px;

    color: $white;
  }

  &__title {
    max-width: 600px;
    color: $white;
    z-index: 9;
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      padding-left: 10px;
      @include fluid-type($min_width, $max_width, (32.5px/1.5), 32.5px, 2/1.5, 2);
      color: $white;
      font-weight: 200;
      margin-bottom: 1rem;
    }

    mark {
      background-color: #f4f4f4;
      color: $body-color;
      box-shadow: 10px 0 0 #f4f4f4, -10px 0 0 #f4f4f4;
    }
  }

  &__images {
    padding: 2rem 0;
    margin: 0 auto;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 3fr 6fr 3fr;
    -ms-grid-columns: 3fr 6fr 3fr;

    > * {
      &:nth-of-type(1) {
        grid-column: 1;
        grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-row: 1;

        margin-top: 20%;
        margin-left: 25%;
        max-width: 75%;
      }
      &:nth-of-type(2) {
        grid-column: 2;
        grid-row: 1 / span 2;
        -ms-grid-column: 2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
      }
      &:nth-of-type(3) {
        grid-column: 3;
        grid-row: 1;
        -ms-grid-column: 3;
        -ms-grid-row: 1;

        margin-top: 12%;
        max-width: 70%;
        margin-left: -12%;
      }
      &:nth-of-type(4) {
        grid-column: 3;
        grid-row: 2 / span 1;
        -ms-grid-column: 3;
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;

        margin-left: 6%;
        max-width: 90%;
      }
    }
  }

  &__headerafbeelding{
    &:nth-child(2) {
      transform: scale(0.75);
    }
  }
}

@include media-breakpoint-up(md) {
  .image-header {
    &__images {
      width: 54vw;
    }
  }
}


@include media-breakpoint-up(lg) {
  .image-header {
    margin-top: 60px;
  }
}

@include media-breakpoint-up(xl) {
  .image-header {
    margin-top: 128px;
  }
}