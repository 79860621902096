.core-value {
  position: relative;
  background-color: $white;
  padding: 1.25rem 1.875rem;

  &__title {
    margin-bottom: 0.25rem;
    @include fluid-type($min_width, $max_width, (27.5px/1.6), 27.5px, 1.1, 1.1);
    font-weight: 200;
  }

  a {
    text-decoration: none;
    color: $body-color;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 30px rgba(0,0,0,.15);
      opacity: 0;
      border-radius: $border-radius;

      @include transition(0.3s);
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  &.highlighted {
    display: flex;
    align-items: center;
    padding: 1.875rem;
    background-color: $green;
    @include fluid-type($min_width, $max_width, (27.5px/1.6), 27.5px, 1.1, 1.1);
    font-weight: 200;

    a {
      color: $white;
    }

    span:after {
      content: "›";
      margin-left: 0.35rem;
    }
  }
}

.core-value-wrapper {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-gap: 1.875rem;

  display: -ms-grid;
  -ms-grid-columns: 6fr 1.875rem 6fr;
  -ms-grid-rows: 6fr 1.875rem 6fr 1.875rem 6fr;

  flex: 1 auto;

  > * {
    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 3;
      -ms-grid-row: 3;
    }

    &:nth-of-type(5) {
      -ms-grid-column: 1;
      -ms-grid-row: 5;
    }

    &:nth-of-type(6) {
      -ms-grid-column: 3;
      -ms-grid-row: 5;
    }
  }
}

.core-value-wrapper-highlighted {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 6fr;
  grid-gap: 1.875rem;

  display: -ms-grid;
  -ms-grid-columns: 6fr 1.875rem 6fr;
  -ms-grid-rows: 6fr 1.875rem 6fr 1.875rem 6fr;

  flex: 1 auto;
}

@include media-breakpoint-up(md) {
  .core-value-wrapper {
    grid-template-columns: 6fr;

    -ms-grid-columns: 6fr;
    -ms-grid-rows: 6fr 1.875rem 6fr 1.875rem 6fr 1.875rem 6fr 1.875rem 6fr 1.875rem 6fr;

    > * {

      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      &:nth-of-type(2) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }

      &:nth-of-type(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }

      &:nth-of-type(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 7;
      }

      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 9;
      }

      &:nth-of-type(6) {
        -ms-grid-column: 1;
        -ms-grid-row: 11;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .core-value-wrapper {
    grid-template-columns: 6fr 6fr;

    -ms-grid-columns: 6fr 1.875rem 6fr;
    -ms-grid-rows: 6fr 1.875rem 6fr 1.875rem 6fr;

    > * {

      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }

      &:nth-of-type(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }

      &:nth-of-type(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }

      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }

      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }
    }
  }
}