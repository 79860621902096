@each $name, $color in $dezon-colors {
  .btn-#{$name} {
    background-color: $color;
    @include transition(0.3s);
    color: white;

    &:hover {
      background-color: darken($color,10%);
      color: white;
    }

    &.btn--outline {
      background-color: transparent;
      border-color: $color;

      &:hover {
        background-color: $color;
      }
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 0 1rem;
  height: 35px;
  @extend %default-font;
  @include fluid-type($min_width, $max_width, (18px/1.125), 18px, 1.4, 1.4);
  text-decoration: none;
  border-radius: 20px;
  border: none;
  cursor: pointer;

  &-default {
    @extend .btn-blue;
  }

  &-primary {
    @extend .btn-blue;
  }

  &-secondary {
    @extend .btn-yellow;
  }

  &--outline {
    border-width: 2px;
    border-style: solid;
    color: $body-color;
  }
}

@include media-breakpoint-up(lg) {
  .btn {
    padding: 0 1.5rem;
  }
}