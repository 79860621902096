footer {
  background-color: $white;
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter footer__header right-gutter"
          "left-gutter footer__body right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;

  padding: 1rem 0;
  border-bottom: 34px solid $blue-dark;
}

.footer__header {
  grid-area: footer__header;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
}

.footer__body {
  grid-area: footer__body;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
}

.footer-block {
  margin-bottom: 2rem;
  text-align: center;

  &:nth-of-type(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(2) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(4) {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(5) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-column-span: 1;

    margin-bottom: 0;
  }

  color: $body-color;

  h6 {
    margin-bottom: 0.75rem;
  }

  a {
    color: $body-color;
    text-decoration: none;
    transition: 0.3s;

    &:not(.share-icon):before {
      content: "›";
      @include fluid-type($min_width, $max_width, (22px/1.6), 22px, 1.1, 1.1);
      margin-right: 0.35rem;
    }

    &.highlighted {
      color: $primary;
    }

    &:hover {
      color: $primary;
    }
  }

  p {
    margin: 0;
  }

  ul.list--default, ul.list {
    margin-bottom: 1rem;
  }
}

.footer-logo {
  text-align: center;
  &__image {
    img {
      max-width: 220px;
    }
  }
}

@include media-breakpoint-up(sm) {

  .footer__body {
    display:grid;
    grid-template-columns: 2fr 2fr;
    grid-column-gap: 2rem;

    display: -ms-grid;
    -ms-grid-columns: 2fr 2rem 2fr;
  }
}

@include media-breakpoint-up(md) {
  .footer__body {
    grid-template-columns: 2fr 2fr;
    -ms-grid-columns: 2fr 2rem 2fr;
  }

  .footer-block {
    text-align: inherit;

    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
      -ms-grid-column-span: 1;
    }
  }

  .footer-logo {
    text-align: center;
    &__image {
      img {
        max-width: 280px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .footer-logo {
    text-align: center;
    &__image {
      img {
        max-width: 394px;
      }
    }
  }

  .footer__body {
    grid-template-columns: 2fr 2fr 2fr 2fr;
    -ms-grid-columns: 2fr 2rem 2fr 2rem 2fr 2rem 2fr;
  }

  .footer-block {
    margin-bottom: 0;

    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 7;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }
  }
}