ul.list--share {
  position: relative;
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    padding: 0;
    margin-right: 8px;
    text-align: center;
    float: left;

    &:before {
      display: none;
    }

    a {
      color: $white;
      text-decoration: none;
      i {
        background-color: transparent;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        width: 36px;
        height: 36px;
        text-align: center;
        border-radius: 18px;
        border: 3px solid transparent;
        @include transition(0.3s);
        backface-visibility: hidden;
      }
      &:hover {
        i {
          transform: scale(1.12);
          border: 3px solid $grey;
        }
      }
    }

    &:nth-child(1) {
      i {
        background-color: $pink;
      }
      &:hover {
        i {
          border: 4px solid lighten($pink,10%);
        }
      }
    }
    &:nth-child(2) {
      i {
        background-color: $blue;
      }
      &:hover {
        i {
          border: 4px solid lighten($blue,10%);
        }
      }
    }
    &:nth-child(3) {
      i {
        background-color: $green;
      }
      &:hover {
        i {
          border: 4px solid lighten($green,10%);
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .share {
    ul {
      li {
        display: block;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .share {
    ul {
      li {
        display: inline-block;
      }
    }
  }
}