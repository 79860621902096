$white:           #fff;
$grey:            #898f97;
$black:           #000;

$green:           #8dc540;
$red:             #f5831d;
$pink:            #e9466d;
$blue:            #3798dc;
$blue-dark:       #275b94;
$blue-darker:     #0c4973;
$yellow:          #f2d223;

$primary:         $blue;
$secondary:       $green;

$body-color:      #434444;
$headings-color:  $blue-dark;

$dezon-colors:(
        yellow: $yellow,
        red: $red,
        pink: $pink,
        blue: $blue,
        blue-dark: $blue-dark,
        green: $green
);
