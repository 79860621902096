.accordion {
  .card {
    background-color: transparent;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    margin-bottom: 0.5rem;

    &-header {
      .custom-arrow {
        float: right;
        &:before {
          border-left-color: $headings-color;
          transform: translate(-10px, 5px) rotate(-135deg);
          transition: all .5s ease;
        }
      }
      h3 {
        cursor: pointer;
      }
      h3[aria-expanded="true"] {
        color: $primary;
        .custom-arrow {
          float: right;
          &:before {
            border-left-color: $primary;
            transform: translate(-10px, 5px) rotate(45deg);
          }
        }
      }
    }

    p:last-of-type {
      margin-bottom: 0.75rem;
    }
  }
}