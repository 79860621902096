.text-header {
  background-color: $white;
  position: relative;
  z-index: 0;
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter header-content right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;

  &__content {
    grid-column: header-content;
    -ms-grid-column: 2;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &__title {
    color: $white;
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .text-header {
    margin-top: 128px;
  }
}