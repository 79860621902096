figure.figure-progressive {
  background-color: $light;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  //16:9 resolution
  padding-bottom: 67%;
  margin: 0;

  //1:1 resolution
  &.figure-square {
    padding-bottom: 100%;
  }

  &.figure-circle {
    padding-bottom: 100%;
    border-radius: 50%;
  }

  &.figure-polygon {
    padding-bottom: 100%;
    //border-radius: 50%;
    transform: rotate(45deg) !important;
    img {
      height: 140% !important;
      width: 140% !important;
      top: -20%;
      left: -21%;
      transform: rotate(-45deg) !important;
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s linear;

      &.loaded {
        opacity: 1;
      }
    }
  }

  img {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s linear;
    &.loaded {
      opacity: 1;
    }
  }

  .image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(50px);
  }

  .image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .loaded {
    opacity: 1;
  }
}